<template>
  <div v-loading="is_pending" class="presence-main slide-in-left">
    <div class="presence-main-header">
        <h2>Presence Viewer</h2>
        <div @click="$emit('close_model')" class="exit-btn">
            <i class="material-symbols-rounded ">chevron_left</i>
        </div>
    </div>
    <div class="presence-main-refresh">
        <span @click="handle_refresh" class="material-symbols-rounded refresh-icon">refresh</span>
    </div>
    <div class="presence-main-content">
        <template v-for="user in users" :key="user.user_uid">
          <UserDetails :user="user"/>
        </template>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_users_online_from_db} from './scripts/scripts'
import UserDetails from './components/UserDetails.vue'
export default {
    components:{UserDetails},
    setup(){
        const is_pending = ref(false)
        const users = ref([])
    
        const handle_refresh = ()=>{
            init()
        }

        const init = async()=>{
            is_pending.value = true
            users.value =  await get_users_online_from_db()
            is_pending.value = false
        }

        init()

        return{
            handle_refresh,
            is_pending,
            users,
        }
    }
}
</script>

<style scoped>

    .presence-main{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 300px;
        height: 90%;
        background: #fff;
        border-radius: 0 10px 0 0;
        padding: 0 5px;
    }
    .slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @-webkit-keyframes slide-in-left {
        0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
        }
        100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
        }
    }
    @keyframes slide-in-left {
        0% {
        -webkit-transform: translateX(-1000px);
                transform: translateX(-1000px);
        opacity: 0;
        }
        100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
        }
    }
    .presence-main-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .exit-btn{
        transform: translateY(-40px);
        right: 0;
        left: unset;
    }
    .exit-btn i{
        font-size: 25px;
    }
    .presence-main-refresh{
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .refresh-icon{
        cursor: pointer;
        user-select: none;
    }
    .presence-main-content{
        width: 100%;
        height: calc(100% - 80px);
        overflow: hidden;
        overflow-y: auto;
    }
    

</style>