<template>
    <div ref="user_details" v-loading="is_pending" class="user-details">
        <div class="user-details-header">
            <p v-if="user.user_name" class="user_name">{{user.user_name}}</p>
            <p v-else class="user_name">No Display Name</p>
        </div>
        <div class="user-details-content">
            <p class="user-detail">
                <span class="user-detail-bold">user uid:</span>
                {{user.user_uid}}
            </p>
            <p class="user-detail">
                <span class="user-detail-bold">Date & time:</span>
                {{display_date_and_time_from_iso(user.created_at)}}
            </p>
            <p class="user-detail">
                <span class="user-detail-bold">Total Time Online:</span>
                {{
                    time_left(user.created_at)
                }}
            </p>
            <div ref="user_details_options" v-loading="is_pending" class="user-details-options">
                <div class="user_details_options-header">
                    <p class="user_name">Options</p>
                </div>
                <div class="user_details_options-btns">
                    <button @click="selectes_option='E.Name'" class="option-btn bg-success">E.Name</button>
                    <button @click="selectes_option='E.Photo'" class="option-btn bg-dark">E.Photo</button>
                    <button @click="selectes_option='E.Phone'" class="option-btn bg-warning">E.Phone</button>
                </div>
                <div class="user_details_options-content">
                    <input v-if="selectes_option=='E.Name'" v-model="user_copy.user_name" class="edit-input" type="text" placeholder="Display Name">
                    <input v-if="selectes_option=='E.Photo'" v-model="user_copy.photo_url" class="edit-input" type="text" placeholder="Photo Url">
                    <input v-if="selectes_option=='E.Phone'" v-model="user_copy.phone" class="edit-input" type="text" placeholder="E.Phone">
                    <button @click="handle_update" class="update-btn bg-success">Update</button>
                </div>
            </div>
            <span @click="handle_show_options" class="material-symbols-rounded options-icon">settings</span>
            <img class="profile-img" v-if="user && !user.photo_url" src="https://firebasestorage.googleapis.com/v0/b/beezi1.appspot.com/o/Icons%2Fprofile_img.jpg?alt=media&token=fc045fd3-39aa-45b3-b74c-7f927d80685b" alt="">
            <img class="profile-img" v-if="user && user.photo_url" :src="user.photo_url" alt="">
        </div>
        
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { display_date_and_time_from_iso,get_object_date_with_time,time_diff_calc} from '../../../../Scripts/dates'
import {slide_pop_error,alert} from '../../../../Scripts/Msgs'
import {set_user_display_name,set_user_phone_number,set_user_profile_image} from '../../../Scripts/User_comands'
export default {
    props:['user'],
    setup(props){

        const is_pending = ref(false)

        const err_msg = ref('')

        const selectes_option = ref('E.Name')

        const user_copy = ref(JSON.parse(JSON.stringify(props.user)))
        const user_details = ref()
        const user_details_options = ref()
        
        const handle_show_options = ()=>{
            user_details.value.classList.toggle('margin-btm')
            user_details_options.value.classList.toggle('show-options')
        }
        const time_left = (created_at)=>{
           const time =  time_diff_calc(new Date(),get_object_date_with_time(created_at))
           return `Days:${time[0]} Hours:${time[1]} min:${time[2]}`
        }

        const validation = (selectes_option)=>{
            switch (selectes_option) {
                case 'E.Name':
                    if(!user_copy.value.user_name){
                        err_msg.value = 'עליך להזין שם מלא!'
                        return false
                    }
                    break;
                case 'E.Photo':
                    if(!user_copy.value.photo_url){
                        err_msg.value = 'עליך להזין כתובת URL לתמונה!'
                        return false
                    }
                    if(!user_copy.value.photo_url.includes('https://')){
                        err_msg.value = 'עליך להזין כתובת URL תקינה!'
                        return false
                    }
                    break;
                case 'E.Phone':
                    if(!user_copy.value.phone.includes('+') || user_copy.value.phone.length!=13){
                        err_msg.value = 'עליך להזין מספר טלפון תקין!'
                        return false
                    }
                    break;
            }
            return true
        }

        const handle_update = async()=>{
            is_pending.value = true
            if(!validation(selectes_option.value)){
                slide_pop_error(err_msg.value)
            }else{
                switch (selectes_option.value) {
                    case 'E.Name':
                        try{
                            await set_user_display_name(user_copy.value.user_name,user_copy.value.user_uid)
                            props.user.user_name = user_copy.value.user_name
                            alert('success','success','User Name updated successfully')
                            handle_show_options()
                        }catch(err){
                            alert('error','error',err.message)
                        }   
                        break;
                    case 'E.Photo':
                        try{
                            await set_user_profile_image(user_copy.value.photo_url,user_copy.value.user_uid)
                            props.user.photo_url = user_copy.value.photo_url
                            alert('success','success','User Photo URL updated successfully')
                            handle_show_options()
                        }catch(err){
                            alert('error','error',err.message)
                        }   
                        break;
                    case 'E.Phone':
                        try{
                            await set_user_phone_number(user_copy.value.phone,user_copy.value.user_uid)
                            props.user.phone = user_copy.value.phone
                            alert('success','success','User Phone updated successfully')
                            handle_show_options()
                        }catch(err){
                            alert('error','error',err.message)
                        }   
                        break;
            
                }
            }
            is_pending.value = false
        }

        return{
            time_left,
            display_date_and_time_from_iso,
            handle_show_options,
            handle_update,
            user_details_options,
            user_details,
            user_copy,
            selectes_option,
            err_msg,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .user-details{
        width: 100%;
        height: 70px;
        margin-bottom: 5px;
        border: 1px solid lightgray;
        border-radius: 5px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.26);
    }
    .user-details.margin-btm{
        margin-bottom: 75px;
    }
    .user-details-header,.user_details_options-header{
        width: 100%;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid lightgray;
        background: var(--turkiz_dark);
        color: #fff;
        overflow: hidden;
        border-radius: 5px 5px 0 0;
    }
    .user_name{
        font-size: 14px; 
    }
    .user-details-content{
        position: relative;
        width: 100%;
        height: calc(100% - 15px);
        padding: 2px;
        line-height: 1.4;
    }
    .options-icon{
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 1;
    }
    .options-icon:hover{
        color: var(--warning);
    }
    .user-detail{
        font-size: 12px;
    }
    .user-detail-bold{
        font-weight: 500;
    }
    .user-details-options{
        position: absolute;
        width:100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: all 0.2s ease-out;
        border: 1px solid lightgray;
        border-radius: 5px;
    }
    .user-details-options.show-options{
        transform: translateY(55px);
        z-index: 0;
        opacity: 1;
        height: 70px;
    }
    .user_details_options-header{
        background: var(--yellow);
        color: #333;
    }
    .user_details_options-btns{
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        padding: 0 5px;
        justify-content: space-evenly;
    }
    .option-btn{
        border: 0;
        padding: 4px;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
    }
    .user_details_options-content{
        width: 100%;
        height: calc(100% - 15px - 25px);
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .edit-input{
        width: 80%;
        border: 1px solid lightgray;
        height: 20px;
        outline: none;
        padding: 0 5px;
        border-radius: 5px;
    }
    .update-btn{
        border-radius: 5px;
        padding: 2px 5px;
        margin-left: 2px;
        border: 0;
        cursor: pointer;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
    }
    .profile-img{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        z-index: 1;
        transition: transform 0.4s ease-in;
    }
    .profile-img:hover{
        transform: scale(2.5) translateX(-10px);
    }
</style>